<template>
  <div>
    <OrganismsGsdBar :vertical="vertical" />

    <molecules-hero-header
      :image-config="{
        img: `${APP_ASSET_BASEURL}edge/static/images/hero/energy_hero_images_v2_1x.webp`,
        class: 'max-w-full w-[28rem] lg:h-[23.44rem]',
        dimensions: {
          width: 448,
          height: 380
        }
      }"
      :buttons="[
        {
          theme: 'primary',
          text: 'GET A QUICK QUOTE',
          url: verticalData.formPage,
          crossApp: true
        }
      ]"
      img-alt="Compare energy"
    >
      <template #header>
        <strong class="font-extrabold">Compare Energy</strong>
      </template>

      <template #sub-header>
        You could be saving between $266 and $553 compared to the energy reference price.*<br />
        Use our
        <img
          class="inline w-24"
          :src="expertEaseImg"
          alt="Expert ease"
          width="96"
          height="17"
        />
        to cut the cost of your gas and electricity.
      </template>
    </molecules-hero-header>

    <MoleculesHomeIconContent 
      :items="FEATURE_ITEMS"
      icon-class="xs:w-[24px] md:w-[32px]"
      section-class="xs:py-8 md:py-10 lg:pt-10 lg:pb-10"
    />

    <molecules-home-company-comparison
      :assets-base-url="`${APP_ASSET_BASEURL}energy/partners/`"
      :companies="PARTNER_ITEMS"
      :show-more="false"
      class="!bg-white"
      theme="v2"
      is-center
      is-carousel
      image-lazy-load
      carousel-class= "lg:w-full"
      card-class="lg:exact-w-[7.875rem]"
      max-width= "max-w-wrapper-lg"
      title-class= "xs:text-2.5xl lg:text-4.5xl"
      sub-title-class="xs:text-base md:text-lg max-w-wrapper-md mx-auto lg:px-[36px]"
    >
      <template #header>
        Our <strong class="font-extrabold">Energy</strong> Retailers
      </template>

      <template #sub-header>
        These logos represent the brands that we compare. We do not compare all products on the market and
        we are not necessarily recommending any of the brands displayed. You will need to talk to a financial advisor to identify 
        the product that best suits your needs.
      </template>
    </molecules-home-company-comparison>

    <molecules-home-great-deal
      :cards="GREAT_DEAL_ITEMS"
      :col-count="4"
      :button-config="{
        class: '!text-sm'
      }"
      card-class="p-6"
      card-wrapper-max-width="w-wrapper-lg"
      class="!bg-blue-5"
      image-lazy-load
    >
      <template #header>
        Welcome to <strong class="font-extrabold">Compare Club Energy</strong>
      </template>

      <template #sub-header>
        Here are some ways you can get the most out of your home energy plan.
      </template>
    </molecules-home-great-deal>

    <molecules-home-saving-plan
      :verticals="SAVING_PLAN_ITEMS"
      class="bg-white"
      card-image-class="mx-auto"
      layout="alternate-items"
      image-lazy-load
    >
      <template #header>
        Understand how <strong class="font-extrabold"><br class="md:hidden" />we can help you save.</strong>
      </template>
    </molecules-home-saving-plan>

    <molecules-home-talk-to-us
      class="bg-white pt-12 pb-10 lg:pt-0 lg:pb-20"
      theme="dark"
      :buttons="[
        {
          label: 'GET A QUOTE',
          to: verticalData.formPage,
          hasIcon: true,
          crossApp: true
        }
      ]"
      image-lazy-load
      hide-right-content-on-mobile
    >
      <template #header>
        Are you <strong class="font-extrabold">paying too much</strong> for<br />your electricity and gas?
      </template>

      <template #sub-header>
        Save time and money as we do the heavy lifting. Our expert team takes care of everything, from finding you the lowest possible price, to handling all your paperwork. We'll even apply any relevant discounts you're eligible
        for.
      </template>

      <template #right-content>
        <NuxtImg
          :src="`${APP_ASSET_BASEURL}edge/static/images/products/energy/expert.svg`"
          alt="Expert"
          width="259"
          height="248.9"
          class="m-auto pb-6"
          loading="lazy"
        />
      </template>
    </molecules-home-talk-to-us>

    <section class="!bg-blue-5">
      <div class=" max-w-wrapper-sm m-auto py-10 lg:py-16 px-4">
        <p class="text-2xl md:text-[32px] lg:text-center mb-4 md:mb-2 lg:mb-6"> How are <strong class="font-extrabold">energy costs calculated?</strong></p>
        <div class="text-base mx-auto">
          <p class="mb-4 ">
            Depending on the terms of your plan, your <NuxtLink class="underline" to="/energy/electricity-charges">energy costs</NuxtLink> may be calculated based on a single/flat rate or on a time-of-use tariff<sup>2</sup>.
          </p>
          <p class="mb-4">
            Single/flat rate plans typically charge the same rate regardless of the time of day or night that the energy is used.
          </p>
          <p class="mb-4">
            Time-of-use tariffs, on the other hand, apply different rates to energy used during peak and off-peak periods.
          </p>
          <p>
            Your energy supplier usually multiples your energy use (measured in kilowatt hours) by the relevant rate according to your plan<sup>3</sup>.
          </p>
        </div>
      </div>
    </section>

    <molecules-home-icon-content 
      :items="DOCUMENTS_TO_START.documentItems"
      theme="white"
      title-class="xs:text-2xl"
      sub-title-class="md:text-center"
      item-container-class="max-w-wrapper-md mx-auto"
    >
      <template #header>
        <strong class="font-extrabold">What documents do you need</strong> to have to get started with an energy provider?
      </template>
      <template #sub-header>
        {{ DOCUMENTS_TO_START.subTitle }}
      </template>
    </molecules-home-icon-content>

    <section class="bg-blue-5">
      <div class="px-4 max-w-wrapper-md m-auto py-10 lg:py-16">
        <p class="text-2xl md:text-[32px] text-left md:text-center pb-6">
          Top tips to <strong class="font-extrabold">save on energy and gas</strong>
        </p>
        <div class="max-w-wrapper-md mx-auto">
          <p class="text-base md:text-lg mb-6 md:mb-8 px-0 w-full md:max-w-wrapper-lg mx-auto md:text-center">
            Compare Club can help find you a cheaper energy rate. But you can <NuxtLink class="underline" to="/energy/use-less-power-home/"> supercharge your savings </NuxtLink> with a few simple easy energy-saving tips:
          </p>
        </div>
        <div class="max-w-wrapper-sm mx-auto">
          <ul>
            <li 
              v-for="(item, index) in TIPS_TO_SAVE" 
              :key="index" 
              class="flex items-start mb-4 last:mb-0 text-sm md:text-base"
            >
              <!-- Checkmark Icon -->
              <NuxtImg
                src="/icons/check-circle-dark.svg"
                width="32"
                height="32"
                alt="Checked"
                loading="lazy"
                class="pt-0.5 pr-1.5 flex-shrink-0"
              />
              <!-- Text -->
              <span class="text-gray-800">{{ item.description }}</span>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <molecules-home-icon-content 
      :items="SWITCHING_PROVIDERS.providers"
      theme="white"
      item-per-row="2"
      title-container-class="xs:max-w-wrapper-lg md:px-12"
      title-class="w-full text-left"
      item-content-class="xs:text-sm md:text-base"
      sub-title-class="md:text-center"
      item-container-class="max-w-wrapper-md mx-auto"
    >
      <template #header>
        <strong class="font-extrabold">Switching</strong> energy providers
      </template>
      <template #sub-header>
        Compare Club makes it easy to <NuxtLink class="underline font-bold" to="/energy/faqs-switching-providers/">switch to a new energy provider</NuxtLink>. However, there are a few things you can supply to help us speed up the process:
      </template>
    </molecules-home-icon-content>

    <molecules-home-icon-content 
      :items="KEYTERMS_ITEMS"
      title-class="xl:w-full text-left xs:text-2xl"
      sub-title-class="md:text-center text-sm"
      item-container-class="max-w-wrapper-md mx-auto"
      item-description-class="text-sm"
    >
      <template #header>
        <strong class="font-extrabold">Key terms</strong>
      </template>
    </molecules-home-icon-content>

    <molecules-home-guide-tiles
      :guides="GUIDES_ITEMS"
      :action-button-url="VERTICALS[vertical].guidesUrl"
      bg-class="xs:pt-10 lg:pt-16 lg:pb-16"
      image-lazy-load
    >
      <template #header>
        All your <strong class="font-extrabold">Energy questions answered</strong><br />
        with our in depth guides
      </template>
    </molecules-home-guide-tiles>

    <molecules-faq
      id="faq"
      :list="FAQ_ITEMS"
      :multiple-collapse="false"
      title="Electricity and Gas FAQs"
      class="bg-gray-10"
    >
      Here are some of the most frequently asked questions we receive about electricity and gas:
    </molecules-faq>

    <molecules-home-footer-info theme="white-boxed">
      <template #title>
        Things you should know
      </template>

      <template #description>
        <p>
          <NuxtLink
            class="text-primary underline"
            to="/"
          >
            CompareClub.com.au
          </NuxtLink>
          is an on-line energy comparison service and is owned and operated by Compare Club Australia Pty Ltd (ACN: 634 600 007).
          We have partnered with Zembl to provide you access to their expert staff to assist in selecting the most appropriate energy packages from their panel of providers.
          Compare Club doesn’t compare all products from all retailers and not all products are available to all customers.
          In providing a comparison, we don’t necessarily compare all features that may be relevant to you.
          Please consider the individual product terms and conditions carefully to ensure the product is right for you.
        </p>

        <p>
          * Savings are calculated compared to the Energy Reference price for a single-rate energy meter based on Compare Club’s best offer as of 25th July 2024.
        </p>
      </template>
    </molecules-home-footer-info>
  </div>
</template>

<script setup>
import {
  FEATURE_ITEMS,
  PARTNER_ITEMS,
  DOCUMENTS_TO_START,
  KEYTERMS_ITEMS,
  TIPS_TO_SAVE,
  SWITCHING_PROVIDERS,
  GREAT_DEAL_ITEMS,
  SAVING_PLAN_ITEMS,
  GUIDES_ITEMS,
  FAQ_ITEMS
} from '~/constants/pages/energy'

const trustpilotStore = useTrustpilotStore()

const vertical = VERTICAL.ENERGY
const verticalData = VERTICALS[vertical]
const $route = useRoute()

defineOptions({
  name: 'Energy'
})

generateMeta({
  title: 'Compare Electricity & Gas | Compare Club',
  ogTitle: 'Compare Electricity & Gas with Compare Club',

  description: 'Save time and money by easily finding a plan that cuts your costs. Compare leading energy companies and let us handle your transfer paperwork.',
  ogDescription: 'Save time and money by easily finding a plan that cuts your costs. Compare leading energy companies and let us handle your transfer paperwork.',

  ogUrl: 'https://compareclub.com.au/energy/',
  ogType: 'product'
}, pageIsFunnel($route))

useJsonld(() => {
  return {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: 'Energy provider comparison',
    image: 'https://compareclub.com.au/_ipx/s_190x30/images/cc-logo-new.svg',
    url: 'https://compareclub.com.au/energy/',
    description: 'We bring together a network of energy suppliers and compare their offered rates on different electricity and gas plans. We put the power in your hands to decide which price is the best and provide the switching service free of charge.',
    brand: {
      '@type': 'Brand',
      name: 'Compare Club'
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: trustpilotStore.trustpilotData?.trustScore,
      bestRating: '5',
      worstRating: '1',
      ratingCount: trustpilotStore.trustpilotData?.numberOfReviews.total
    }
  }
})
</script>
